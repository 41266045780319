import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ConfigProvider, theme } from 'antd';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
const firebaseConfig = {
  apiKey: "AIzaSyDenMsHedwTD5w3f4UV-SLdOsf-ItYmzRQ",
  authDomain: "vidicut-2aee2.firebaseapp.com",
  projectId: "vidicut-2aee2",
  storageBucket: "vidicut-2aee2.appspot.com",
  messagingSenderId: "649744799604",
  appId: "1:649744799604:web:df4a7d467f73776430be21",
  measurementId: "G-P7XN8GB25B"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
	<React.StrictMode>
		<ConfigProvider
			theme={{
				components: {
					Slider: {
						handleSize: 20,
						handleSizeHover: 20,
					},
				},
				algorithm: theme.darkAlgorithm,
				token: { colorPrimary: '#00b96b' },
			}}
		>
			<App />
		</ConfigProvider>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
