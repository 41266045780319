export const isValid = (start: number, end: number) => {
	if (start === end) {
		return false;
	}
	if (start > end) {
		return false;
	}
	if (start < 0) {
		return false;
	}
	if (end < 0) {
		return false;
	}
	if (end - start < 1) {
		return false;
	}
	return true;
};
export const formatTime = (seconds: number) => {
	const totalMilliseconds = Math.round(seconds * 1000);
	const totalSeconds = Math.floor(totalMilliseconds / 1000);
	const hours = Math.floor(totalSeconds / 3600);
	const minutes = Math.floor((totalSeconds % 3600) / 60);
	const secondsPart = totalSeconds % 60;
	const millisecondsDisplay = Math.floor((totalMilliseconds % 1000) / 100);

	if (hours > 0) {
		return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(secondsPart).padStart(2, '0')}.${String(millisecondsDisplay)}`;
	} else {
		return `${String(minutes).padStart(2, '0')}:${String(secondsPart).padStart(2, '0')}.${String(millisecondsDisplay)}`;
	}
};

export const parseTime = (formattedTime: string): { value: number; error: boolean } => {
	try {
		formattedTime = formattedTime.replace(/\s+/g, '');
		// Updated regex to capture both HH:mm:ss.ms and mm:ss.ms formats
		const regex = /(?:(\d{2}):)?(\d{2}):(\d{2})\.(\d)/;
		const matches = formattedTime.match(regex);
		console.log('ma', matches);
		if (!matches) {
			throw new Error('Invalid time format');
		}

		// Determine if hours are present and parse accordingly
		const hours = matches[1] ? parseInt(matches[1], 10) : 0; // Hours (if present)
		const minutes = matches[2] ? parseInt(matches[2], 10) : matches[1] ? 0 : parseInt(matches[3], 10); // Minutes or seconds (if no hours)
		const seconds = matches[2] ? parseInt(matches[3], 10) : parseInt(matches[2], 10); // Seconds
		const milliseconds = matches[4] ? parseInt(matches[4], 10) * 100 : 0; // Convert tenths to milliseconds

		// Calculate total seconds
		const totalSeconds = hours * 3600 + minutes * 60 + seconds + milliseconds / 1000;
		if (isNaN(totalSeconds)) {
			throw new Error('error');
		}
		return { value: totalSeconds, error: false };
	} catch (e) {
		return { value: -99, error: true };
	}
};
