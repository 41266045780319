import React, { useState } from 'react';
import { Slider, Button, Typography, Row, SliderSingleProps, Input } from 'antd';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { formatTime, isValid, parseTime } from './util';
import { If } from './Conditional';

const { Title, Paragraph } = Typography;

interface CutMarkerSetterProps {
	duration: number;
	start: number;
	end: number;
	setStart: (value: number) => void;
	setEnd: (value: number) => void;
	addMarker: () => void;
	onChangeComplete: (values: number[]) => void;
	disabled: boolean;
}
const formatter: NonNullable<SliderSingleProps['tooltip']>['formatter'] = value => formatTime(value || 0);

export const CutMarkerSetter: React.FC<CutMarkerSetterProps> = ({ duration, start, end, setStart, setEnd, addMarker, onChangeComplete, disabled }) => {
	const [isEditingStart, setIsEditingStart] = useState(false);
	const [isEditingEnd, setIsEditingEnd] = useState(false);
	const [inputStartValue, setInputStartValue] = useState(formatTime(start));
	const [inputEndValue, setInputEndValue] = useState(formatTime(end));
	const [inputStartError, setInputStartError] = useState(false);
	const [inputEndError, setInputEndError] = useState(false);
	const modify = (type: 'start' | 'end', amount: number) => {
		const newStart = type === 'start' ? amount : start;
		const newEnd = type === 'end' ? amount : end;
		if (!isValid(newStart, newEnd)) return false;
		if (type === 'start') setStart(amount);
		if (type === 'end') setEnd(amount);
		return true;
	};

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, type: 'start' | 'end') => {
		if (type === 'start') setInputStartValue(e.target.value);
		if (type === 'end') setInputEndValue(e.target.value);
	};
	const handleStartInputBlur = () => {
		const { value, error } = parseTime(inputStartValue);
		let isModifySuccess = false;
		if (!error) isModifySuccess = modify('start', value);
		if (!isModifySuccess) setInputStartValue(formatTime(start));
		const success = isModifySuccess && !error;
		if (success) setIsEditingStart(false);
		setInputStartError(!success);
	};
	const handleEndInputBlur = () => {
		const { value, error } = parseTime(inputEndValue);
		let isModifySuccess = false;
		if (!error) isModifySuccess = modify('end', value);
		if (!isModifySuccess) setInputEndValue(formatTime(end));
		const success = isModifySuccess && !error;
		if (success) setIsEditingEnd(false);
		setInputEndError(!success);
	};
	return (
		<div style={{ flex: 1 }}>
			<Title level={5}>Set Cut Markers</Title>
			<Paragraph>Drag the sliders below to set the start and end points for the video cut.</Paragraph>
			<Row style={{ flex: 1 }}>
				<Slider
					style={{ marginLeft: 15, marginRight: 15, flex: 1 }}
					range
					step={0.1}
					min={0}
					tooltip={{ formatter }}
					max={duration}
					value={[start, end]}
					onChange={(values: number[]) => {
						setInputStartValue(formatTime(values[0]));
						setInputEndValue(formatTime(values[1]));
						setStart(values[0]);
						setEnd(values[1]);
					}}
					onChangeComplete={(values: number[]) => {
						onChangeComplete(values);
					}}
					marks={{
						0: '0s',
						[Math.floor(duration / 2)]: formatTime(duration / 2),
						[Math.floor(duration)]: formatTime(duration),
					}}
				/>
			</Row>
			<Row style={{ flex: 1, justifyContent: 'center', marginTop: 15 }}>
				<Button.Group size='small' style={{ flex: 1, justifyContent: 'center', marginRight: 5 }}>
					<Button icon={<MinusOutlined />} onClick={() => modify('start', start - 0.1)} style={{ flex: 1 }} />
					<If condition={isEditingStart}>
						<Input
							status={inputStartError ? 'error' : ''}
							size='small'
							style={{ flex: 1, marginRight: 1, marginLeft: 1 }}
							value={inputStartValue}
							onChange={e => handleInputChange(e, 'start')}
							onBlur={handleStartInputBlur}
							onPressEnter={handleStartInputBlur}
							autoFocus
						/>
					</If>
					<If condition={!isEditingStart}>
						<Button style={{ flex: 1 }} onClick={() => setIsEditingStart(true)}>
							Start {formatTime(start)}
						</Button>
					</If>
					<Button icon={<PlusOutlined />} onClick={() => modify('start', start + 0.1)} style={{ flex: 1 }} />
				</Button.Group>
				<Button.Group size='small' style={{ flex: 1, justifyContent: 'center', marginLeft: 5 }}>
					<Button style={{ flex: 1 }} icon={<MinusOutlined />} onClick={() => modify('end', end - 0.1)} />
					<If condition={isEditingEnd}>
						<Input
							status={inputEndError ? 'error' : ''}
							size='small'
							style={{ flex: 1, marginRight: 1, marginLeft: 1 }}
							value={inputEndValue}
							onChange={e => handleInputChange(e, 'end')}
							onBlur={handleEndInputBlur}
							onPressEnter={handleEndInputBlur}
							autoFocus
						/>
					</If>
					<If condition={!isEditingEnd}>
						<Button style={{ flex: 1 }} onClick={() => setIsEditingEnd(true)}>
							End {formatTime(end)}
						</Button>
					</If>
					<Button icon={<PlusOutlined />} onClick={() => modify('end', end + 0.1)} style={{ flex: 1 }} />
				</Button.Group>
			</Row>
			<Row style={{ flex: 1, justifyContent: 'center' }}>
				<Button style={{ flex: 1, marginTop: 10 }} size='large' type='primary' icon={<PlusOutlined />} onClick={addMarker} disabled={disabled} loading={disabled}>
					{disabled ? 'Loading' : 'Add'}
				</Button>
			</Row>
		</div>
	);
};
