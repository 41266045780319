import { ReloadOutlined } from '@ant-design/icons';
import { FFmpeg } from '@ffmpeg/ffmpeg';
import { fetchFile } from '@ffmpeg/util';
import { Button, Col, Layout, message, Modal, Row, Typography } from 'antd';
import JSZip from 'jszip';
import React, { useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { v4 as uuidv4 } from 'uuid';
import { If } from './Conditional';
import { CutMarkerSetter } from './CutMarkerSetter';
import { MarkerList } from './MarkerList';
import { UploadButton } from './UploadButton';
import { isValid } from './util';
import { VideoPlayerModal } from './VideoPlayerModal';
import MyImage from './images/logo512.png';
import { Marker } from './types';
const { Title, Paragraph } = Typography;
const { Content, Header } = Layout;

const App: React.FC = () => {
	const ffmpegRef = useRef(new FFmpeg());
	const [videoFile, setVideoFile] = useState<File | null>(null);
	const [videoUrl, setVideoUrl] = useState<string>('');
	const [markers, setMarkers] = useState<Marker[]>([]);
	const [isFFmpegLoaded, setIsFFmpegLoaded] = useState<boolean>(false);
	const [start, setStart] = useState<number>(0);
	const [end, setEnd] = useState<number>(1);
	const [duration, setDuration] = useState<number>(0);
	const videoPlayerRef = useRef<ReactPlayer | null>(null);
	const [startPreview, setStartPreview] = useState<number>(0);
	const [endPreview, setEndPreview] = useState<number>(0);
	const [playing, setPlaying] = useState<boolean>(true);
	const topRef = useRef<HTMLDivElement | null>(null);
	const bottomRef = useRef<HTMLDivElement | null>(null);
	const [topHeight, setTopHeight] = useState(0);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [previewUrl, setPreviewUrl] = useState<string>('');
	const [zipping, setIsZipping] = useState(false);

	useEffect(() => {
		const ffmpeg = ffmpegRef.current;
		const loadFFmpeg = async () => {
			if (!ffmpeg.loaded) {
				await ffmpeg.load();
				setIsFFmpegLoaded(true);
			}
		};
		loadFFmpeg();
		const deleteFiles = async () => {
			// try {
			// 	await ffmpeg.load();
			// 	await ffmpeg.deleteFile('input.mp4');
			// } catch (e) {
			// 	console.log('error delete file', e);
			// }
			// for (let i = 0; i < markers.length; i++) {
			// 	try {
			// 		const fileName = markers[i].fileName;
			// 		if (typeof fileName === 'string' && fileName.length > 0) {
			// 			await ffmpeg.deleteFile(fileName);
			// 		}
			// 	} catch (e) {
			// 		console.log('error delete file', e);
			// 	}
			// }
		};
		return () => {
			deleteFiles();
		};
	}, []);
	const handleVideoUpload = (file: File) => {
		setVideoFile(file);
		setVideoUrl(URL.createObjectURL(file));
		return false;
	};
	const addMarker = async (fileName: string = '', start: number, end: number) => {
		if (!videoFile || !isFFmpegLoaded) {
			return;
		}
		if (start >= end) {
			message.error('Start time must be less than end time.');
			return;
		}
		let url: string | undefined = undefined;
		try {
			console.log('herer');
			message.loading('Processing...', 0);
			const ffmpeg = ffmpegRef.current;
			await ffmpeg.writeFile('input.mp4', await fetchFile(videoFile));
			const outputName = fileName || `vidicut-${uuidv4()}.mp4`;
			if (fileName) await ffmpeg.deleteFile(fileName);
			await ffmpeg.exec(['-i', 'input.mp4', '-ss', start.toString(), '-to', end.toString(), '-c', 'copy', outputName]);
			const data = await ffmpeg.readFile(outputName);
			url = URL.createObjectURL(new Blob([data], { type: 'video/mp4' }));
			if (fileName) {
				setMarkers(markers =>
					markers.map(e => {
						if (e.fileName !== outputName) return e;
						return { ...e, url };
					})
				);
			} else {
				setMarkers([...markers, { start, end, url, fileName: outputName }]);
			}
		} catch (e) {
			message.error(`An error occurred while processing the video ${e}`);
		} finally {
			message.destroy();
		}
		setStart(0);
		setEnd(0);
		return url;
	};
	const deleteMarker = async (index: number) => {
		const selectedMarker = markers[index];
		const ffmpeg = ffmpegRef.current;
		if (selectedMarker.fileName) {
			await ffmpeg.deleteFile(selectedMarker.fileName);
		}
		setMarkers(markers.filter((_, i) => i !== index));
	};
	const showModal = () => setIsModalVisible(true);
	const handleOk = () => setIsModalVisible(false);
	const handleCancel = () => setIsModalVisible(false);
	const playPreview = async (index: number) => {
		setMarkers(g => g.map((v, i) => (i === index ? { ...v, isLoading: true } : v)));
		try {
			if (!markers[index] || !markers[index].url) {
				return;
			}
			const marker = markers[index];
			setPlaying(false);
			const url = await addMarker(marker.fileName, marker.start, marker.end);
			if (url) {
				setPreviewUrl(url);
				showModal();
			}
		} catch (e) {
			message.destroy();
			message.error('An error occurred while playing the video.');
		}
		setMarkers(g => g.map((v, i) => (i === index ? { ...v, isLoading: false } : v)));
	};
	const handleProgress = (progress: { playedSeconds: number }) => {
		if (!isValid(startPreview, endPreview)) {
			setPlaying(false);
			return;
		}
		if (progress.playedSeconds >= endPreview && videoPlayerRef.current) {
			videoPlayerRef.current.seekTo(startPreview, 'seconds');
			setPlaying(true);
		}
	};
	const prevEnd = useRef(endPreview);
	useEffect(() => {
		if (!isValid(startPreview, endPreview)) {
			setPlaying(false);
			return;
		}
		if (!videoPlayerRef.current) return;
		if (endPreview !== prevEnd.current) {
			videoPlayerRef.current.seekTo(endPreview - 0.8, 'seconds');
		} else {
			videoPlayerRef.current.seekTo(startPreview, 'seconds');
		}
		setPlaying(true);
		prevEnd.current = endPreview;
	}, [startPreview, endPreview]);
	useEffect(() => {
		setStartPreview(start);
		setEndPreview(end);
	}, [end, start]);
	const downloadZip = async () => {
		message.loading('Processing...', 0);
		const ffmpeg = ffmpegRef.current;
		setIsZipping(true);
		const zip = new JSZip();
		const folder = zip.folder('vidicut_clips');
		for (let i = 0; i < markers.length; i++) {
			const marker = markers[i];
			try {
				if (marker.fileName) await ffmpeg.deleteFile(marker.fileName);
			} catch {}

			await ffmpeg.exec(['-i', 'input.mp4', '-ss', marker.start.toString(), '-to', marker.end.toString(), '-c', 'copy', marker.fileName]);
			const data = await ffmpeg.readFile(marker.fileName);

			const url = URL.createObjectURL(new Blob([data], { type: 'video/mp4' }));
			if (!url) continue;
			const response = await fetch(url);
			const blob = await response.blob();
			folder?.file(`cut-${i}.mp4`, blob);
		}
		const content = await zip.generateAsync({ type: 'blob' });
		const link = document.createElement('a');
		link.href = URL.createObjectURL(content);
		link.download = 'vidicut_clips.zip';
		link.click();
		setIsZipping(false);
		message.destroy();
	};
	const starOver = () => {
		setVideoFile(null);
		setVideoUrl('');
		setMarkers([]);
		setStart(0);
		setEnd(10);
		setDuration(0);
		setStartPreview(0);
		setEndPreview(0);
		setPlaying(false);
	};
	useEffect(() => {
		const updateHeight = () => {
			if (topRef.current) setTopHeight(topRef.current.offsetHeight);
		};
		updateHeight();
		window.addEventListener('resize', updateHeight);
		return () => {
			window.removeEventListener('resize', updateHeight);
		};
	}, [videoUrl]);
	const modify = (type: 'start' | 'end', amount: number, index: number) => {
		let success = true;
		const updatedMarkers = markers.map((marker, i) => {
			if (i === index) {
				const newStart = type === 'start' ? amount : marker.start;
				const newEnd = type === 'end' ? amount : marker.end;
				if (!isValid(newStart, newEnd)) {
					success = false;
					return marker;
				}
				return { ...marker, start: newStart, end: newEnd };
			}
			return marker;
		});
		setMarkers(updatedMarkers);
		if (type === 'start') {
			setStartPreview(amount);
		}
		if (type === 'end') {
			setEndPreview(amount);
		}
		return success;
	};
	const onChangeComplete = (values: number[]) => {};
	const download = async (index: number) => {
		setMarkers(g => g.map((v, i) => (i === index ? { ...v, isLoading: true } : v)));
		const url = await addMarker(markers[index].fileName, markers[index].start, markers[index].end);
		if (!url) return;
		const link = document.createElement('a');
		link.href = url;
		link.download = markers[index].fileName || '';
		link.click();
		setMarkers(g => g.map((v, i) => (i === index ? { ...v, isLoading: false } : v)));
	};
	return (
		<Layout className='container'>
			<Content style={{ maxWidth: '9000px', alignSelf: 'center', margin: 20, marginTop: 0 }}>
				<If condition={isModalVisible}>
					<VideoPlayerModal markers={markers} isModalVisible={isModalVisible} handleOk={handleOk} handleCancel={handleCancel} previewUrl={previewUrl} />
				</If>
				<Row style={{ justifyContent: 'center' }}>
					<If condition={videoUrl === ''}>
						<UploadButton handleVideoUpload={handleVideoUpload} />
					</If>
				</Row>
				<If condition={videoUrl !== ''}>
					<Col ref={topRef} style={{ ...topStyle, justifyContent: 'center' }}>
						<Row style={{ justifyContent: 'center', flex: 1, alignItems: 'center', marginBottom: 12 }}>
							<Header style={{ backgroundColor: 'black' }}>
								<Col style={{ flex: 1 }}>
									<Row style={{ justifyContent: 'center', alignItems: 'center' }}>
										<Row>
											<img
												src={MyImage}
												alt='Description of the'
												height={'40px'}
												style={{
													borderRadius: 15,
													borderColor: 'rgba(255, 255, 255, 0.3)',
													borderStyle: 'solid',
													marginRight: 10,
												}}
											/>
										</Row>
										<Row style={{ justifyContent: 'center', alignItems: 'center' }}>
											<Typography.Text style={{ fontSize: '32px' }}>Vidicut</Typography.Text>
										</Row>
									</Row>
								</Col>
							</Header>
						</Row>
						<Row style={{ justifyContent: 'center' }}>
							<ReactPlayer
								config={{ file: { attributes: { playsInline: true } } }}
								width={'auto'}
								height={'200px'}
								playing={playing}
								onProgress={handleProgress}
								url={videoUrl}
								ref={videoPlayerRef}
								controls
								onDuration={setDuration}
							/>
						</Row>
					</Col>
				</If>
				<Row style={{ marginTop: topHeight }}>
					<If condition={videoUrl !== ''}>
						<Button onClick={starOver} icon={<ReloadOutlined />}>
							Start Over
						</Button>
					</If>
				</Row>
				<Row>
					<If condition={videoUrl !== ''}>
						<CutMarkerSetter
							disabled={!isFFmpegLoaded}
							onChangeComplete={onChangeComplete}
							addMarker={() => addMarker('', start, end)}
							duration={duration}
							setEnd={setEnd}
							setStart={setStart}
							end={end}
							start={start}
						/>
					</If>
				</Row>
				<Row>
					<MarkerList download={download} modify={modify} markers={markers} playPreview={playPreview} deleteMarker={deleteMarker} />
				</Row>
				<Row ref={bottomRef}>
					<If condition={markers.length > 0}>
						<Button type='primary' onClick={downloadZip} loading={zipping} style={{ marginTop: 10, flex: 1 }} size='large'>
							{zipping ? 'Zipping...' : 'Download all clips'}
						</Button>
					</If>
				</Row>
			</Content>
		</Layout>
	);
};
export default App;

const topStyle: React.CSSProperties = {
	position: 'fixed',
	left: '50%',
	transform: 'translateX(-50%)',
	backgroundColor: '#000000',
	color: 'white',
	zIndex: 1000,
	padding: '20px',
	paddingTop: 0,
	width: '100%',
	// boxSizing: 'border-box',
};
