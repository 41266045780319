import React, { useEffect, useLayoutEffect, useState } from 'react';
import { List, Button, Typography, Popconfirm, Row, Input } from 'antd';
import { MinusOutlined, PlusOutlined, DeleteOutlined, PlayCircleOutlined, DownloadOutlined } from '@ant-design/icons';
import { formatTime, parseTime } from './util';
import Card from 'antd/es/card/Card';
import { If } from './Conditional';
import { Marker } from './types';
const { Title } = Typography;

interface MarkerOnSteroid extends Marker {
	isEditingStart: boolean;
	isEditingEnd: boolean;
	inputStartValue: string;
	inputEndValue: string;
	inputStartError: boolean;
	inputEndError: boolean;
}
interface MarkerListProps {
	markers: Marker[];
	deleteMarker: (index: number) => void;
	playPreview: (index: number) => void;
	download: (index: number) => void;
	modify: (type: 'start' | 'end', amount: number, index: number) => boolean;
}

export const MarkerList: React.FC<MarkerListProps> = ({ markers, deleteMarker, playPreview, modify, download }) => {
	const [steroidMarkers, setSteroidMarkers] = useState<MarkerOnSteroid[]>([]);
	useEffect(() => {
		const newMarkers = markers.map(marker => {
			const existing = steroidMarkers.find(sm => sm.fileName === marker.fileName);
			return {
				...marker,
				inputStartValue: formatTime(marker.start),
				inputEndValue: formatTime(marker.end),
				isEditingStart: existing?.isEditingStart || false,
				isEditingEnd: existing?.isEditingEnd || false,
				inputStartError: existing?.inputStartError || false,
				inputEndError: existing?.inputEndError || false,
			};
		});
		setSteroidMarkers(newMarkers);
	}, [markers]);

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, index: number, type: 'start' | 'end') => {
		const value = e.target.value;
		setSteroidMarkers(prev => prev.map((marker, i) => (i === index ? { ...marker, [type === 'start' ? 'inputStartValue' : 'inputEndValue']: value } : marker)));
	};

	const handleInputBlur = (index: number, type: 'start' | 'end') => {
		setSteroidMarkers(prev =>
			prev.map((marker, i) => {
				if (i !== index) return marker;

				const { value, error } = parseTime(marker[type === 'start' ? 'inputStartValue' : 'inputEndValue']);
				const success = !error && modify(type, value, index);

				return {
					...marker,
					[type]: success ? value : marker[type],
					[type === 'start' ? 'inputStartValue' : 'inputEndValue']: formatTime(success ? value : marker[type]),
					[type === 'start' ? 'isEditingStart' : 'isEditingEnd']: !success,
					[type === 'start' ? 'inputStartError' : 'inputEndError']: !success,
				};
			})
		);
	};

	const setIsEditingTrue = (index: number, type: 'start' | 'end') => {
		setSteroidMarkers(prev => prev.map((marker, i) => (i === index ? { ...marker, [type === 'start' ? 'isEditingStart' : 'isEditingEnd']: true } : marker)));
	};
	if (steroidMarkers.length === 0) return null;
	return (
		<div style={{ flex: 1 }}>
			<Title level={5}>Clips</Title>
			<List
				dataSource={steroidMarkers}
				renderItem={(marker, index) => {
					return (
						<List.Item>
							<Card style={{ flex: 1, justifyContent: 'center' }} size='small'>
								<Row style={{ flex: 1 }}>
									<Button.Group size='small' style={{ flex: 1, justifyContent: 'center', marginRight: 5 }}>
										<Button icon={<MinusOutlined />} onClick={() => modify('start', marker.start - 0.1, index)} style={{ flex: 1 }} />
										<If condition={marker.isEditingStart}>
											<Input
												status={marker.inputStartError ? 'error' : ''}
												size='small'
												style={{ flex: 1, marginRight: 1, marginLeft: 1 }}
												value={marker.inputStartValue}
												onChange={e => handleInputChange(e, index, 'start')}
												onBlur={() => handleInputBlur(index, 'start')}
												onPressEnter={() => handleInputBlur(index, 'start')}
												autoFocus
											/>
										</If>
										<If condition={!marker.isEditingStart}>
											<Button style={{ flex: 1 }} onClick={() => setIsEditingTrue(index, 'start')}>
												Start {formatTime(marker.start)}
											</Button>
										</If>
										<Button icon={<PlusOutlined />} onClick={() => modify('start', marker.start + 0.1, index)} style={{ flex: 1 }} />
									</Button.Group>
									<Button.Group size='small' style={{ flex: 1, justifyContent: 'center', marginLeft: 5 }}>
										<Button icon={<MinusOutlined />} onClick={() => modify('end', marker.end - 0.1, index)} style={{ flex: 1 }} />
										<If condition={marker.isEditingEnd}>
											<Input
												status={marker.inputEndError ? 'error' : ''}
												size='small'
												style={{ flex: 1, marginRight: 1, marginLeft: 1 }}
												value={marker.inputEndValue}
												onChange={e => handleInputChange(e, index, 'end')}
												onBlur={() => handleInputBlur(index, 'end')}
												onPressEnter={() => handleInputBlur(index, 'end')}
												autoFocus
											/>
										</If>
										<If condition={!marker.isEditingEnd}>
											<Button style={{ flex: 1 }} onClick={() => setIsEditingTrue(index, 'end')}>
												End {formatTime(marker.end)}
											</Button>
										</If>
										<Button icon={<PlusOutlined />} onClick={() => modify('end', marker.end + 0.1, index)} style={{ flex: 1 }} />
									</Button.Group>
								</Row>
								<Row>
									<Button.Group style={{ flex: 1, justifyContent: 'center', marginTop: 10 }}>
										<Button onClick={() => playPreview(index)} loading={marker.isLoading} disabled={marker.isLoading} icon={<PlayCircleOutlined />} style={{ flex: 1 }}>
											Preview
										</Button>
										<Button loading={marker.isLoading} disabled={marker.isLoading} onClick={() => download(index)} icon={<DownloadOutlined />} style={{ flex: 1 }}>
											Download
										</Button>
										<Popconfirm title='Delete clip' description='Are you sure to delete this clip?' onConfirm={() => deleteMarker(index)} okText='Yes' cancelText='No'>
											<Button danger icon={<DeleteOutlined />} iconPosition={'start'} style={{ flex: 1 }}>
												Delete
											</Button>
										</Popconfirm>
									</Button.Group>
								</Row>
							</Card>
						</List.Item>
					);
				}}
			/>
		</div>
	);
};
