import React from 'react';
import { Button, Modal } from 'antd';
import ReactPlayer from 'react-player';
import { Marker } from './types';

interface VideoPlayerModalProps {
	isModalVisible: boolean;
	handleOk: () => void;
	handleCancel: () => void;
	previewUrl: string;
	markers: Marker[];
}

export const VideoPlayerModal: React.FC<VideoPlayerModalProps> = ({ isModalVisible, handleOk, handleCancel, previewUrl, markers }) => {
	const handleDownload = async () => {
		if (!previewUrl) return;
		const link = document.createElement('a');
		link.href = previewUrl;
		link.download = markers.find(e => e.url === previewUrl)?.fileName || 'vidicut';
		link.click();
	};
	return (
		<Modal
			title='Video Player'
			open={isModalVisible}
			onOk={handleOk}
			onCancel={handleCancel}
			footer={[
				<Button key='download' onClick={handleDownload}>
					Download
				</Button>,
				<Button key='close' onClick={handleCancel}>
					Close
				</Button>,
			]}
		>
			<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
				<ReactPlayer
					config={{
						file: {
							attributes: {
								playsInline: true,
							},
						},
					}}
					width={'auto'}
					height={'200px'}
					playing={isModalVisible}
					url={previewUrl}
					controls
					loop
				/>
			</div>
		</Modal>
	);
};
