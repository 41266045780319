import React from 'react';
import { Button, Upload, Row, Col, Typography } from 'antd';
import { UploadOutlined, VideoCameraOutlined } from '@ant-design/icons';
import MyImage from './images/logo512.png';
interface VideoUploaderProps {
	handleVideoUpload: (file: File) => boolean; // Define prop type for the function
}

export const UploadButton: React.FC<VideoUploaderProps> = ({ handleVideoUpload }) => {
	return (
		<Row
			style={{
				height: '100vh',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
			}}
		>
			<Col style={{ flex: 1, justifyContent: 'center' }}>
				<Row style={{ justifyContent: 'center', alignItems: 'center' }}>
					<Row>
						<img
							src={MyImage}
							alt='Description of the'
							height={'40px'}
							style={{
								borderRadius: 15,
								borderColor: 'rgba(255, 255, 255, 0.3)',
								borderStyle: 'solid',
								marginRight: 10,
							}}
						/>
					</Row>
					<Row>
						<Typography.Title>Vidicut</Typography.Title>
					</Row>
				</Row>
				<Row>
					<Upload accept='video/*' beforeUpload={handleVideoUpload} showUploadList={false}>
						<Button
							icon={<VideoCameraOutlined />}
							size='large' // Make the button larger
							style={{ width: '250px', height: '60px', fontSize: '16px', borderRadius: 30 }}
						>
							Select video
						</Button>
					</Upload>
				</Row>
			</Col>
		</Row>
	);
};
