import React from 'react';

type IfProps = {
    condition: boolean | undefined | null;
    children: React.ReactNode;
};
/**
 * this component only render childs if the condition is true.
 */
export const If: React.FC<IfProps> = props => {
    if (!!props.condition) {
        return <>{props.children}</>;
    } else {
        return null;
    }
};
